import Vue from 'vue';
import Vuetify, { VBtn } from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import '@mdi/font/css/materialdesignicons.css';


Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: colors.shades.white,
        secondary: colors.shades.black,
        accent: colors.grey.lighten2,
        error: colors.red.accent3,
        lightgray: colors.grey.lighten3
      },
      dark: {
        primary: "#B2BDCD",
        secondary: "#1D4367",
        accent: '#121416',
        error: colors.red.accent3,
        lightgray: '#121416',
        sidenav: '#121416',
        btncolor: '#ffff00'
      }
    },
  },
});
